/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { BtcAccount, ConnectedMethods, PhantomBtcProvider, TLog } from '../../types';
import { SupportedBtcChainIds } from '../../constants/chains';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { TestId } from '../../components/TestId';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
import styled from 'styled-components';
import { GRAY } from '../../constants';
import getProvider from '../../utils/btc/getProvider';
import { signMessage, signPsbt } from './connectedMethods';

// =============================================================================
// Styles
// =============================================================================

const UnprivilegedActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Title = styled.h4`
  color: ${GRAY};
`;

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  accounts: BtcAccount[];
  connectedMethods: ConnectedMethods[]; // what do we show when we're connected
  handleConnect: () => Promise<void>;

  // generic
  logs: TLog[];
  clearLogs: () => void;
  logsVisibility: boolean;
  toggleLogs: () => void;
}

// =============================================================================
// Hooks
// =============================================================================

/**
 * @DEVELOPERS
 * The fun stuff!
 */
const useProps = (): Props => {
  const [provider, setProvider] = useState<PhantomBtcProvider | null>(null);
  const [accounts, setAccounts] = useState<BtcAccount[]>([]);
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();

  useEffect(() => {
    const prov = getProvider();
    setProvider(prov);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(window as any).phantom]);

  useEffect(() => {
    if (!provider) return;

    provider.on('accountsChanged', (btcAccounts: BtcAccount[]) => {
      if (btcAccounts.length > 0) {
        // Set new address and continue as usual
        setAccounts(btcAccounts);
        createLog({
          status: 'info',
          method: 'accountChanged',
          message: `Switched to accounts ${btcAccounts.map((account) => account.address).join(', ')}`,
        });
      } else {
        // Attempt to reconnect to Phantom
        createLog({
          status: 'info',
          method: 'accountChanged',
          message: 'Attempting to switch accounts.',
        });
        handleConnect();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const handleConnect = useCallback(async () => {
    if (!provider) return;

    try {
      const btcAccounts = await provider.requestAccounts();
      setAccounts(btcAccounts);
    } catch (error) {
      createLog({
        status: 'error',
        method: 'connect',
        message: error.message,
      });
    }
  }, [provider, createLog]);

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Sign PSBT',
        chainIds: [SupportedBtcChainIds.BtcMainnet],
        onClick: () => signPsbt(provider, createLog),
      },
      {
        name: 'Sign Message',
        chainIds: [SupportedBtcChainIds.BtcMainnet],
        onClick: () => signMessage(provider, createLog, accounts[0].address),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, accounts]);

  return {
    accounts,
    connectedMethods,
    handleConnect,
    logs,
    clearLogs,
    logsVisibility,
    toggleLogs,
  };
};

// =============================================================================
// Main Component
// =============================================================================

const App = () => {
  const { accounts, connectedMethods, handleConnect, logs, clearLogs, logsVisibility, toggleLogs } = useProps();
  const connected = accounts?.[0] != null;
  return (
    <AppWrapper>
      <Sidebar logsVisibility={logsVisibility} toggleLogs={toggleLogs} activePath="/btc-sandbox">
        {accounts?.[0]?.address && accounts?.[1]?.address && (
          <ConnectedAs
            addresses={{
              evm: null,
              solana: null,
              btcTaproot: accounts[0].address,
              btcSegwit: accounts[1].address,
            }}
          />
        )}
        {!connected && (
          <UnprivilegedActions>
            <Column>
              <Title>Connect</Title>
              <div>
                <Button onClick={handleConnect} data-testid="connect">
                  Connect to Phantom
                </Button>
                <TestId id="connect" />
              </div>
            </Column>
          </UnprivilegedActions>
        )}

        <ActionButtons
          connectedMethods={connectedMethods.map((method) => {
            return {
              ...method,
            };
          })}
          connected={connected}
        />
      </Sidebar>
      {logsVisibility && <Logs connected={connected} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

const AppWithProviders = () => {
  return (
    <LogsProvider>
      <App />
    </LogsProvider>
  );
};

export default AppWithProviders;

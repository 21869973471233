/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Sidebar from '../../components/Sidebar';
import { EVMNetworkSelector } from '../../components/NetworkSelector';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { ConnectedMethods, TLog } from '../../types';
import { SupportedEVMChainIds } from '../../constants/chains';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';
import { useSyncProviders } from './hooks/useSyncProviders';
import { EIP6963ProviderDetail } from '../../types';

const WalletContainer = styled.div`
  padding: 4px 16px;
  margin-bottom: 24px;
`;

const WalletList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const WalletButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
  border: 1px solid #2d2d2d;
  border-radius: 8px;
  background: transparent;
  color: inherit;
  width: 100%;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const WalletIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 4px;
`;

const SectionTitle = styled.h2`
  font-size: 1.1rem;
  margin-bottom: 4px;
`;

const SelectedWallet = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
  border: 1px solid #2d2d2d;
  border-radius: 8px;
`;

const WalletName = styled.span`
  font-weight: 500;
`;

const WalletAddress = styled.span`
  color: #666;
  font-family: monospace;
  font-size: 0.9em;
`;

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  connectedMethods: ConnectedMethods[];
  handleConnect: (providerWithInfo: EIP6963ProviderDetail) => Promise<void>;
  network: SupportedEVMChainIds;
  setNetwork: (network: SupportedEVMChainIds) => void;
  logs: TLog[];
  clearLogs: () => void;
  logsVisibility: boolean;
  toggleLogs: () => void;
  providers: EIP6963ProviderDetail[];
  userAccount: string;
  selectedWallet: EIP6963ProviderDetail;
}

// =============================================================================
// Hooks
// =============================================================================

/**
 * @DEVELOPERS
 * The fun stuff!
 */
const useProps = (): Props => {
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();
  const [network, setNetwork] = useState<SupportedEVMChainIds>(SupportedEVMChainIds.EthereumMainnet);
  const [selectedWallet, setSelectedWallet] = useState<EIP6963ProviderDetail>();
  const [userAccount, setUserAccount] = useState<string>('');
  const providers = useSyncProviders();

  /** Connect */
  const handleConnect = async (providerWithInfo: EIP6963ProviderDetail) => {
    const accounts: string[] | undefined = (await providerWithInfo.provider
      .request({ method: 'eth_requestAccounts' })
      .catch(console.error)) as string[] | undefined;

    if (accounts?.[0]) {
      setSelectedWallet(providerWithInfo);
      setUserAccount(accounts?.[0]);
      createLog({
        status: 'success',
        method: 'connect',
        message: `Connected to ${providerWithInfo.info.name} (${accounts[0]})`,
      });
    }
  };

  /** Disconnect */
  const handleDisconnect = useCallback(async () => {
    createLog({
      status: 'warning',
      method: 'disconnect',
      message: '👋',
    });
    setUserAccount('');
  }, [createLog]);

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Disconnect',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleDisconnect,
      },
    ];
  }, [handleDisconnect]);

  return {
    connectedMethods,
    handleConnect,
    network,
    setNetwork,
    logs,
    clearLogs,
    logsVisibility,
    toggleLogs,
    providers,
    userAccount,
    selectedWallet,
  };
};

// =============================================================================
// Main Component
// =============================================================================

const App = () => {
  const {
    connectedMethods,
    handleConnect,
    network,
    setNetwork,
    logs,
    clearLogs,
    logsVisibility,
    toggleLogs,
    providers,
    userAccount,
    selectedWallet,
  } = useProps();

  return (
    <AppWrapper>
      <Sidebar
        logsVisibility={logsVisibility}
        toggleLogs={toggleLogs}
        topSection={EVMNetworkSelector({ network, setNetwork })}
        activePath="/eip-6963-sandbox"
      >
        {userAccount && (
          <ConnectedAs
            addresses={{
              evm: userAccount,
              solana: null,
            }}
          />
        )}
        <WalletContainer>
          <SectionTitle>Wallets Detected</SectionTitle>
          <WalletList>
            {providers.length > 0 ? (
              providers?.map((provider: EIP6963ProviderDetail) => (
                <WalletButton
                  aria-label={`${provider.info.name} detected`}
                  key={provider.info.uuid}
                  onClick={() => handleConnect(provider)}
                >
                  <WalletIcon src={provider.info.icon} alt={provider.info.name} />
                  <WalletName>{provider.info.name}</WalletName>
                </WalletButton>
              ))
            ) : (
              <div>No Announced Wallet Providers</div>
            )}
          </WalletList>
          <SectionTitle>{userAccount ? 'Selected Wallet' : 'No Wallet Selected'}</SectionTitle>
          {userAccount && (
            <SelectedWallet>
              <WalletIcon
                aria-label={`${selectedWallet.info.name} selected`}
                src={selectedWallet.info.icon}
                alt={selectedWallet.info.name}
              />
              <WalletName>{selectedWallet.info.name}</WalletName>
              <WalletAddress>{userAccount}</WalletAddress>
            </SelectedWallet>
          )}
        </WalletContainer>
        <ActionButtons
          connectedMethods={connectedMethods
            .filter((method) => method.chainIds.includes(network))
            .map((method) => {
              return {
                ...method,
                chainIds: [network],
              };
            })}
          connected={!!userAccount}
        />
      </Sidebar>
      {logsVisibility && <Logs connected={!!userAccount} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

const AppWithProviders = () => {
  return (
    <LogsProvider>
      <App />
    </LogsProvider>
  );
};

export default AppWithProviders;

import React from 'react';
import styled from 'styled-components';
import { GRAY, REACT_GRAY, PURPLE, WHITE } from '../../constants';

import { hexToRGB } from '../../utils';

import Button from '../Button';
import { Header } from '../Header';
import { ToggleDevSettings } from '../ToggleDevSettings';
import { MadeWithLoveAtPhantom } from '../MadeWithLoveAtPhantom';

// =============================================================================
// Styled Components
// =============================================================================

const Main = styled.main`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  background-color: ${REACT_GRAY};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100vh;
  > * {
    margin-bottom: 10px;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  margin-bottom: 4px;
`;

const UtilityButton = styled(Button)`
  background-color: ${GRAY};
  color: ${WHITE};

  max-width: 200px;

  &:hover {
    background-color: ${hexToRGB(GRAY, 0.8)};
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  children?: React.ReactNode;
  topSection?: React.ReactNode;
  disableLogs?: boolean;
  logsVisibility: boolean;
  toggleLogs: () => void;
  activePath: string;
}

// =============================================================================
// Main Component
// =============================================================================

const Sidebar = React.memo((props: Props) => {
  const { logsVisibility, toggleLogs, disableLogs } = props;

  const scrollToActions = () => {
    const actions = document.getElementById('action-buttons');
    actions?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Main>
      <Body>
        <Header activePath={props.activePath} />
        <MenuContainer>
          <ButtonsContainer>
            {!disableLogs && (
              <UtilityButton onClick={toggleLogs}>{`${logsVisibility === true ? 'Hide' : 'Show'} Logs`}</UtilityButton>
            )}
            <UtilityButton onClick={scrollToActions}>Scroll to Actions</UtilityButton>
          </ButtonsContainer>
          {props.topSection}
        </MenuContainer>
        {props.children}
      </Body>
      <div>
        <MadeWithLoveAtPhantom />
        <ToggleDevSettings />
      </div>
    </Main>
  );
});

export default Sidebar;

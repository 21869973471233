export enum SupportedEVMChainIds {
  EthereumMainnet = '0x1',
  EthereumSepolia = '0x11155111',
  PolygonMainnet = '0x89',
  PolygonAmoy = '0x80002',
  BaseMainnet = '0x8453',
  BaseSepolia = '0x84532',
  ArbitrumMainnet = '0x421613',
  MonadDevnet = '0x41454',
  MonadMainnet = '0x84534',
}

export enum SupportedBtcChainIds {
  BtcMainnet = '0xbtc',
}

export enum SupportedEVMHexChainIds {
  EthereumMainnet = '0x1',
  EthereumSepolia = '0xaa36a7',
  PolygonMainnet = '0x89',
  PolygonAmoy = '0x13882',
  BaseMainnet = '0x2105',
  BaseSepolia = '0x14a34',
  ArbitrumMainnet = '0x66EED',
  MonadDevnet = '0xA1EE',
  MonadMainnet = '0x14a36',
}

export enum SupportedSolanaChainIds {
  SolanaMainnet = 'solana:101',
  SolanaTestnet = 'solana:102',
  SolanaDevnet = 'solana:103',
}

export enum SupportedChainNames {
  EthereumMainnet = 'Ethereum Mainnet',
  EthereumSepolia = 'Ethereum Sepolia',
  PolygonMainnet = 'Polygon Mainnet',
  PolygonAmoy = 'Polygon Amoy',
  BaseMainnet = 'Base Mainnet',
  BaseSepolia = 'Base Sepolia',
  SolanaMainnet = 'Solana Mainnet Beta',
  SolanaTestnet = 'Solana Testnet',
  SolanaDevnet = 'Solana Devnet',
  ArbitrumMainnet = 'Arbitrum Mainnet',
  MonadDevnet = 'Monad Devnet',
  MonadMainnet = 'Monad Mainnet',
  BtcMainnet = 'Bitcoin Mainnet',
}

export enum SupportedChainExplorers {
  EthereumMainnet = 'https://etherscan.io/',
  EthereumSepolia = 'https://sepolia.etherscan.io/',
  PolygonMainnet = 'https://polygonscan.com/',
  PolygonAmoy = 'https://amoy.polygonscan.com/',
  BaseMainnet = 'https://basescan.org/',
  BaseSepolia = 'https://sepolia.basescan.org/',
  SolanaMainnet = 'https://solscan.io/',
  SolanaTestnet = 'https://solscan.io/',
  ArbitrumMainnet = 'https://arbiscan.io/',
  MonadDevnet = 'https://monadscan.io/',
  MonadMainnet = 'https://monadscan.io/',
  BtcMainnet = 'https://btcscan.org/',
}

export enum SupportedChainIcons {
  Ethereum = '/images/ethereum.png',
  Base = '/images/base.png',
  Polygon = '/images/polygon.png',
  Solana = '/images/solana.png',
  Monad = '/images/monad.png',
  Bitcoin = '/images/bitcoin.png',
}

export type SupportedChain = {
  name: SupportedChainNames;
  chainId: SupportedEVMChainIds | SupportedSolanaChainIds | SupportedBtcChainIds;
  icon: SupportedChainIcons;
  explorer: string;
};

export const SUPPORTED_CHAINS = {
  [SupportedEVMChainIds.EthereumMainnet]: {
    name: SupportedChainNames.EthereumMainnet,
    chainId: SupportedEVMChainIds.EthereumMainnet,
    icon: SupportedChainIcons.Ethereum,
    explorer: SupportedChainExplorers.EthereumMainnet,
    hexChainId: SupportedEVMHexChainIds.EthereumMainnet,
  },
  [SupportedEVMChainIds.EthereumSepolia]: {
    name: SupportedChainNames.EthereumSepolia,
    chainId: SupportedEVMChainIds.EthereumSepolia,
    icon: SupportedChainIcons.Ethereum,
    explorer: SupportedChainExplorers.EthereumSepolia,
    hexChainId: SupportedEVMHexChainIds.EthereumSepolia,
  },
  [SupportedEVMChainIds.PolygonMainnet]: {
    name: SupportedChainNames.PolygonMainnet,
    chainId: SupportedEVMChainIds.PolygonMainnet,
    icon: SupportedChainIcons.Polygon,
    explorer: SupportedChainExplorers.PolygonMainnet,
    hexChainId: SupportedEVMHexChainIds.PolygonMainnet,
  },
  [SupportedEVMChainIds.PolygonAmoy]: {
    name: SupportedChainNames.PolygonAmoy,
    chainId: SupportedEVMChainIds.PolygonAmoy,
    icon: SupportedChainIcons.Polygon,
    explorer: SupportedChainExplorers.PolygonAmoy,
    hexChainId: SupportedEVMHexChainIds.PolygonAmoy,
  },
  [SupportedEVMChainIds.BaseMainnet]: {
    name: SupportedChainNames.BaseMainnet,
    chainId: SupportedEVMChainIds.BaseMainnet,
    icon: SupportedChainIcons.Base,
    explorer: SupportedChainExplorers.BaseMainnet,
    hexChainId: SupportedEVMHexChainIds.BaseMainnet,
  },
  [SupportedEVMChainIds.BaseSepolia]: {
    name: SupportedChainNames.BaseSepolia,
    chainId: SupportedEVMChainIds.BaseSepolia,
    icon: SupportedChainIcons.Base,
    explorer: SupportedChainExplorers.BaseSepolia,
    hexChainId: SupportedEVMHexChainIds.BaseSepolia,
  },
  [SupportedSolanaChainIds.SolanaMainnet]: {
    name: SupportedChainNames.SolanaMainnet,
    chainId: SupportedSolanaChainIds.SolanaMainnet,
    icon: SupportedChainIcons.Solana,
    explorer: SupportedChainExplorers.SolanaMainnet,
  },
  [SupportedSolanaChainIds.SolanaTestnet]: {
    name: SupportedChainNames.SolanaTestnet,
    chainId: SupportedSolanaChainIds.SolanaTestnet,
    icon: SupportedChainIcons.Solana,
    explorer: SupportedChainExplorers.SolanaTestnet,
  },
  [SupportedSolanaChainIds.SolanaDevnet]: {
    name: SupportedChainNames.SolanaDevnet,
    chainId: SupportedSolanaChainIds.SolanaDevnet,
    icon: SupportedChainIcons.Solana,
    explorer: SupportedChainExplorers.SolanaMainnet,
  },
  [SupportedEVMChainIds.ArbitrumMainnet]: {
    name: SupportedChainNames.ArbitrumMainnet,
    chainId: SupportedEVMChainIds.ArbitrumMainnet,
    icon: SupportedChainIcons.Ethereum,
    explorer: SupportedChainExplorers.ArbitrumMainnet,
    hexChainId: SupportedEVMHexChainIds.ArbitrumMainnet,
  },
  [SupportedEVMChainIds.MonadDevnet]: {
    name: SupportedChainNames.MonadDevnet,
    chainId: SupportedEVMChainIds.MonadDevnet,
    icon: SupportedChainIcons.Monad,
    explorer: SupportedChainExplorers.MonadDevnet,
    hexChainId: SupportedEVMHexChainIds.MonadDevnet,
  },
  [SupportedBtcChainIds.BtcMainnet]: {
    name: SupportedChainNames.BtcMainnet,
    chainId: SupportedBtcChainIds.BtcMainnet,
    icon: SupportedChainIcons.Bitcoin,
    explorer: SupportedChainExplorers.BtcMainnet,
  },
};

export const CHAINS = [
  SUPPORTED_CHAINS[SupportedEVMChainIds.EthereumMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.EthereumSepolia],
  SUPPORTED_CHAINS[SupportedEVMChainIds.PolygonMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.PolygonAmoy],
  SUPPORTED_CHAINS[SupportedEVMChainIds.BaseMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.BaseSepolia],
  SUPPORTED_CHAINS[SupportedEVMChainIds.ArbitrumMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.MonadDevnet],
  SUPPORTED_CHAINS[SupportedSolanaChainIds.SolanaMainnet],
  SUPPORTED_CHAINS[SupportedSolanaChainIds.SolanaTestnet],
  SUPPORTED_CHAINS[SupportedSolanaChainIds.SolanaDevnet],
  SUPPORTED_CHAINS[SupportedBtcChainIds.BtcMainnet],
];

export const EVM_CHAINS = [
  SUPPORTED_CHAINS[SupportedEVMChainIds.EthereumMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.EthereumSepolia],
  SUPPORTED_CHAINS[SupportedEVMChainIds.PolygonMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.PolygonAmoy],
  SUPPORTED_CHAINS[SupportedEVMChainIds.BaseMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.BaseSepolia],
  SUPPORTED_CHAINS[SupportedEVMChainIds.ArbitrumMainnet],
  SUPPORTED_CHAINS[SupportedEVMChainIds.MonadDevnet],
];

import type { FC, ReactNode } from 'react';
import React, { createContext } from 'react';
import { useLocalStorage } from './useLocalStorage';

type SetStateAction<S> = React.Dispatch<React.SetStateAction<S>>;

interface EthPreferencesContextState {
  waitForTransactionConfirmation: boolean;
  setWaitForTransactionConfirmation: SetStateAction<boolean>;
}

const EthPreferencesContext = createContext<EthPreferencesContextState>({} as EthPreferencesContextState);

export const useEthPreferences = () => {
  const context = React.useContext(EthPreferencesContext);

  if (!context) {
    throw new Error('useEthPreferences must be used within an EthPreferencesProvider');
  }

  return context;
};

export const EthPreferencesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [waitForTransactionConfirmation, setWaitForTransactionConfirmation] = useLocalStorage(
    'waitForTransactionConfirmation',
    true
  );

  return (
    <EthPreferencesContext.Provider
      value={{
        waitForTransactionConfirmation,
        setWaitForTransactionConfirmation,
      }}
    >
      {children}
    </EthPreferencesContext.Provider>
  );
};

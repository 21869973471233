import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import { ERC_20_TOKENS, TOKEN_LOCKER } from '../../constants/tokens';
import { parseEther } from 'viem';
import { SUPPORTED_CHAINS } from '../../constants/chains';

export async function lockERC20(
  chainId: string,
  provider: Web3Provider,
  amount = parseEther('100')
): Promise<TransactionResponse> {
  try {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const gasPrice = await provider.getGasPrice();
    const tokenAddress = ERC_20_TOKENS[chainId];
    const locker = TOKEN_LOCKER[chainId];

    if (!tokenAddress || !locker) {
      throw new Error('Token address not found');
    }
    const hexChainId = SUPPORTED_CHAINS[chainId as any]?.hexChainId;
    if (!hexChainId) {
      throw new Error('Unsupported chain id');
    }

    // Encode the function signature and parameters
    const lockFunctionSignature = utils.id('lockERC20(address,uint256)').slice(0, 10);
    const encodedParameters = utils.defaultAbiCoder.encode(['address', 'uint256'], [tokenAddress, amount]);
    const data = lockFunctionSignature + encodedParameters.slice(2); // Remove the '0x' from encodedParameters

    const transactionParameters = {
      nonce: await provider.getTransactionCount(address), // ignored by Phantom
      gasPrice, // customizable by user during confirmation.
      // gasLimit: utils.hexlify(100000),
      to: locker, // Required except during contract publications.
      from: address,
      value: 0, // Only required to send ether to the recipient from the initiating external account.
      data: data, // Call to mint() function
      chainId: hexChainId,
    };

    return signer.sendTransaction(transactionParameters);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
}

import { createPhantom } from '@phantom/wallet-sdk';
import { EthPreferencesProvider } from '../../hooks/useEthPreferences';
import { LogsProvider } from '../../hooks/useLogs';
import { getProvider } from '../../utils/evm';
import { App } from '../eth-sandbox/App';
import React, { useState } from 'react';
import { Web3Provider } from '../../types';
import Button from '../../components/Button';
import styled from 'styled-components';
import { GRAY, PURPLE } from '../../constants';
import { hexToRGB } from '../../utils';

const SDKButton = styled.button`
  display: block;
  color: ${GRAY};
  text-decoration: none;
  font-size: 14px;
  padding: 4px 6px;
  background-color: ${hexToRGB(PURPLE, 0.2)};
  border: none;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: white;
    font-weight: bold;
    background: ${hexToRGB(PURPLE, 0.5)};
  }

  &.selected {
    color: white;
    font-weight: bold;
    background: ${hexToRGB(PURPLE, 0.5)};
  }
`;

const SDKButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const AppWithProviders = () => {
  const [provider, setProvider] = useState<Web3Provider | null>(null);

  const sdkURLs = [
    {
      name: 'Localhost',
      value: 'http://localhost:5173/dist/sdk.js',
    },

    {
      name: 'Production',
      value: 'https://embedded-wallet.phantom.app/sdk.js',
    },
  ];

  const [sdkURL, setSDKURL] = useState(sdkURLs[1]);

  const onChangeSDK = (sdk) => {
    setSDKURL(sdk);
  };

  const enableSDK = async () => {
    createPhantom({
      sdkURL: sdkURL.value,
    });

    setTimeout(() => {
      window.addEventListener('phantom#embedded#initialized', () => {
        setTimeout(() => {
          setProvider(getProvider());
        }, 300); // Wait for the provider to be set
      });
    }, 1000);
  };

  return (
    <EthPreferencesProvider>
      <LogsProvider>
        <App provider={provider}>
          {!provider && (
            <div>
              <SDKButtonContainer>
                {sdkURLs.map((sdk) => (
                  <SDKButton
                    key={sdk.value}
                    className={sdkURL.value === sdk.value ? 'selected' : ''}
                    onClick={() => onChangeSDK(sdk)}
                  >
                    {sdk.name}
                  </SDKButton>
                ))}
              </SDKButtonContainer>
              <Button onClick={enableSDK}>Enable SDK</Button>
            </div>
          )}
        </App>
      </LogsProvider>
    </EthPreferencesProvider>
  );
};

export default AppWithProviders;

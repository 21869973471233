import styled from 'styled-components';

import { WHITE, DARK_GRAY, LIGHT_GRAY } from '../../constants';

import { hexToRGB } from '../../utils';

const Button = styled.button`
  cursor: pointer;
  width: 100%;
  color: ${(props) => (props.disabled ? DARK_GRAY : WHITE)};
  background-color: ${(props) => (props.disabled ? LIGHT_GRAY : DARK_GRAY)};
  padding: 8px 4px;
  font-weight: 500;
  outline: 0;
  border: 0;
  border-radius: 6px;
  user-select: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? LIGHT_GRAY : hexToRGB(LIGHT_GRAY, 0.9))};
  }

  &:focus-visible &:not(:hover) {
    background-color: ${(props) => (props.disabled ? LIGHT_GRAY : hexToRGB(LIGHT_GRAY, 0.8))};
  }

  &:active {
    background-color: ${LIGHT_GRAY};
  }
`;

export default Button;

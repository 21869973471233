import { Transaction, Connection, PublicKey, Keypair } from '@solana/web3.js';

// Transaction with no instructions and a random fee payer should throw an error
const createMalformedTransferTransaction = async (
  publicKey: PublicKey,
  connection: Connection
): Promise<Transaction> => {
  const transaction = new Transaction();

  // We're using the public key from an account that we know
  transaction.feePayer = new PublicKey('DS5uHtDEgQQmmyZotsuz1MVEa3Xbu3EwcyZBH3wTFKXk');
  transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  return transaction;
};

export default createMalformedTransferTransaction;

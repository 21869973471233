import * as React from 'react';
import { TLog } from '../types';
import { PropsWithChildren } from 'react';

export type LogsContextState = {
  logs: TLog[];
  logsVisibility: boolean;
  createLog: (log: TLog) => void;
  clearLogs: () => void;
  toggleLogs: () => void;
};

const defaultStateValue: LogsContextState = {
  logs: [],
  logsVisibility: true,
  createLog: () => {},
  clearLogs: () => {},
  toggleLogs: () => {},
};

const LogsContext = React.createContext<LogsContextState>(defaultStateValue);

export const LogsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [logs, setLogs] = React.useState<TLog[]>([]);
  const [logsVisibility, setLogsVisibility] = React.useState(true);

  const createLog = React.useCallback(
    (log: TLog) => {
      return setLogs((logs) => [
        {
          ...log,
          timestamp: Date.now(),
        },
        ...logs,
      ]);
    },
    [setLogs]
  );

  const clearLogs = React.useCallback(() => {
    setLogs([]);
  }, [setLogs]);

  const toggleLogs = React.useCallback(() => {
    setLogsVisibility((prev) => !prev);
  }, [setLogsVisibility]);

  const value = React.useMemo(
    () => ({ logs, logsVisibility, createLog, clearLogs, toggleLogs }),
    [logs, logsVisibility, createLog, clearLogs, toggleLogs]
  );

  return <LogsContext.Provider value={value}>{children}</LogsContext.Provider>;
};

export const useLogs = () => {
  return React.useContext(LogsContext);
};

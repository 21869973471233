import React from 'react';
import styled from 'styled-components';
import { GRAY, PURPLE } from '../../constants';
import { hexToRGB } from '../../utils';
import { TestId } from '../TestId';
import {
  EVM_CHAINS,
  SUPPORTED_CHAINS,
  SupportedChain,
  SupportedEVMChainIds,
  SupportedSolanaChainIds,
} from '../../constants/chains';

const NetworkSelectButton = styled.button`
  display: block;
  color: ${GRAY};
  text-decoration: none;
  font-size: 14px;
  padding: 4px 6px;
  background-color: ${hexToRGB(PURPLE, 0.2)};
  border: none;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: white;
    font-weight: bold;
    background: ${hexToRGB(PURPLE, 0.5)};
  }

  &.selected {
    color: white;
    font-weight: bold;
    background: ${hexToRGB(PURPLE, 0.5)};
  }
`;

const NetworkSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const NetworkContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const Label = styled.p`
  color: ${GRAY};
  white-space: nowrap;
  padding-top: 3px;
`;

const Network = styled.div``;

export function SolanaNetworkSelector({
  network,
  setNetwork,
  networks = [
    SUPPORTED_CHAINS[SupportedSolanaChainIds.SolanaMainnet],
    SUPPORTED_CHAINS[SupportedSolanaChainIds.SolanaDevnet],
  ],
}: {
  network: string;
  setNetwork: (network: string) => void;
  networks?: SupportedChain[];
}) {
  return <NetworkSelector network={network} setNetwork={setNetwork} networks={networks} />;
}

export function EVMNetworkSelector({
  network,
  setNetwork,
}: {
  network: SupportedEVMChainIds;
  setNetwork: (network: SupportedEVMChainIds) => void;
}) {
  return EVM_CHAINS.map((c) => c.chainId).includes(network) ? (
    <NetworkSelector network={network} setNetwork={setNetwork} networks={EVM_CHAINS} />
  ) : (
    <div>
      <div>
        <NetworkSelector network={network} setNetwork={setNetwork} networks={EVM_CHAINS} />
      </div>
      Unsupported network {network}
    </div>
  );
}

function NetworkSelector({
  network,
  setNetwork,
  networks,
}: {
  network: string;
  setNetwork: (network: string) => void;
  networks: SupportedChain[];
}) {
  return (
    <NetworkSelectorContainer data-testid={'network-selector'}>
      <Label>Select Network</Label>
      <NetworkContainer>
        {networks
          .filter((i) => !!i)
          .map(({ name, chainId }) => (
            <Network>
              <NetworkSelectButton
                key={chainId}
                data-testid={`network-selector-${chainId}`}
                onClick={() => setNetwork(chainId)}
                className={network === chainId ? 'selected' : ''}
              >
                {name}
              </NetworkSelectButton>
              <TestId id={`network-selector-${chainId}`} />
            </Network>
          ))}
      </NetworkContainer>
    </NetworkSelectorContainer>
  );
}

import { SUPPORTED_CHAINS, SupportedEVMChainIds } from '../../constants/chains';
import { PhantomEthereumProvider } from '../../types';

/**
 * Switches the ethereum provider to a new chainId
 * @param provider a Phantom ethereum provider
 * @param chainId an EVM chainId to switch to
 * @returns null if successful
 */
const switchEthereumChain = async (
  provider: PhantomEthereumProvider,
  chainId: SupportedEVMChainIds
): Promise<unknown> => {
  try {
    // Get the hex chain id
    const chain = SUPPORTED_CHAINS[chainId];
    if (!chain) {
      throw new Error(`ChainId ${chainId} not supported`);
    }
    const hexChainid = chain.hexChainId;
    const response = await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hexChainid }],
    });
    return response;
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};

export default switchEthereumChain;

import type { FC, ReactNode } from 'react';
import React, { createContext } from 'react';
import { useLocalStorage } from './useLocalStorage';

type SetStateAction<S> = React.Dispatch<React.SetStateAction<S>>;

interface SolPreferencesContextState {
  autoConnect: boolean;
  onlyIfTrusted: boolean;
  logAutoConnectErrors: boolean;
  setAutoConnect: SetStateAction<boolean>;
  setOnlyIfTrusted: SetStateAction<boolean>;
  setLogAutoConnectErrors: SetStateAction<boolean>;
}

const SolPreferencesContext = createContext<SolPreferencesContextState>({} as SolPreferencesContextState);

export const useSolPreferences = () => {
  const context = React.useContext(SolPreferencesContext);

  if (!context) {
    throw new Error('onlyIfTrustedContext must be used within a OnlyIfTrustedProvider');
  }

  return context;
};

export const SolPreferencesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [autoConnect, setAutoConnect] = useLocalStorage('autoConnect', true);
  const [onlyIfTrusted, setOnlyIfTrusted] = useLocalStorage('onlyIfTrusted', true);
  const [logAutoConnectErrors, setLogAutoConnectErrors] = useLocalStorage('logAutoConnect', true);

  return (
    <SolPreferencesContext.Provider
      value={{
        logAutoConnectErrors,
        setLogAutoConnectErrors,
        onlyIfTrusted,
        setOnlyIfTrusted,
        autoConnect,
        setAutoConnect,
      }}
    >
      {children}
    </SolPreferencesContext.Provider>
  );
};

export const PASS = 'PASS ✅';
export const FAIL = 'FAIL ❌';

const durationOrNull = (startMs: number, endMs: number) => {
  return startMs !== null && endMs !== null ? endMs - startMs : null;
};

const validateSpeedDisplay = (speed: number | null, threshold: number) => {
  return speed !== null && speed < threshold ? PASS : FAIL;
};

const validateSpeedA11y = (speed: number | null, threshold: number) => {
  return speed !== null && speed < threshold ? 'pass' : 'fail';
};

type ProviderPerformanceData = {
  _injectionStartMs: number | null;
  _injectionEndMs: number | null;
};

type ProviderInjectionSpeedDataResult = {
  latencyTestResultDisplay: string;
  latencyTestResultA11y: string;
  speedTestResultDisplay: string;
  speedTestResultA11y: string;
  speedValueDisplay: string;
  latencyValueDisplay: string;
};

export const deriveInjectionSpeedData = (provider: ProviderPerformanceData): ProviderInjectionSpeedDataResult => {
  const injectionStartMs = provider?._injectionStartMs ?? null;
  const injectionEndMs = provider?._injectionEndMs ?? null;
  const injectionSpeedMs = durationOrNull(injectionStartMs, injectionEndMs);

  return {
    latencyValueDisplay: injectionEndMs ? `${injectionEndMs.toFixed(2)}ms` : 'null',
    speedValueDisplay: injectionSpeedMs ? `${injectionSpeedMs.toFixed(2)}ms` : 'null',
    speedTestResultDisplay: validateSpeedDisplay(injectionSpeedMs, 300),
    speedTestResultA11y: validateSpeedA11y(injectionSpeedMs, 300),
    latencyTestResultDisplay: validateSpeedDisplay(injectionEndMs, 1500),
    latencyTestResultA11y: validateSpeedA11y(injectionEndMs, 1500),
  };
};
